import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
class Addbuilding extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        const buildingData= {
            building_name:this.BuildingName,
            location:this.Location,
            building_type:this.BuildingType,
            area:this.Area,
            sq_feet:this.SqFeet,
            land_owner:this.LandOwner
        }

        axios.post(process.env.REACT_APP_API_URL+'api/resource/Buildings', buildingData,{
        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
        'content-type': 'application/json'}
    })                
        .then( 
            res =>{ console.log(res) }
        )
        .catch(
            err =>{ console.log(err)}
        )
    }
   
    render() {
      return <div> 
    <div id="layout-wrapper">
            <div className="main-content">

                <div className="page-content">

                 
                    <div className="page-title-box">
                        <div className="container-fluid">
                         <div className="row align-items-center">
                             <div className="col-sm-6">
                                 <div className="page-title">
                                     <h4>Add Building</h4>
                                         <ol className="breadcrumb m-0">
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Add Building</a></li>
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Building</a></li>
                                             <li className="breadcrumb-item active">Add Building</li>
                                         </ol>
                                 </div>
                             </div>
                             <div className="col-sm-6">
                                <div className="float-end d-none d-sm-block">
                                   <Link to="" className="btn btn-success">Add Building</Link>
                                </div>
                             </div>
                         </div>
                        </div>
                     </div>
                 


                    <div className="container-fluid">

                        <div className="page-content-wrapper">

                        


                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Building Details</h4>
    
                                            <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                                <ul className="twitter-bs-wizard-nav">
                                                    <li className="nav-item wizard-border">
                                                        <a href="#seller-details" className="nav-link" data-toggle="tab">
                                                            <span className="step-number">01. Building Details</span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item wizard-border">
                                                        <a href="#company-document" className="nav-link" data-toggle="tab">
                                                            <span className="step-number">02. Images</span>
                                                        </a>
                                                    </li>
                                                    
                                                    <li className="nav-item wizard-border">
                                                        <a href="#bank-detail" className="nav-link" data-toggle="tab">
                                                            <span className="step-number">03. Task </span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="#confirm-detail" className="nav-link" data-toggle="tab">
                                                            <span className="step-number">04. Payment Detail</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content twitter-bs-wizard-tab-content">
                                                    <div className="tab-pane" id="seller-details">
                                                        <form onSubmit={this.handleSubmit}>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-firstname-input">Building Name</label>
                                                                        <input type="text" className="form-control" id="basicpill-firstname-input"
                                                                         onChange={e => this.BuildingName = e.target.value}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-lastname-input">Land</label>
                                                                        <select className="form-select" aria-label="Default select example">
                                                                            <option selected="">Select land</option>
                                                                            <option value="1">One</option>
                                                                            <option value="2">Two</option>
                                                                            <option value="3">Three</option>
                                                                            </select>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-phoneno-input">Area</label>
                                                                        <input type="text" className="form-control" id="basicpill-phoneno-input"
                                                                         onChange={e => this.Area = e.target.value}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-email-input">Sq. Feet</label>
                                                                        <input type="email" className="form-control" id="basicpill-email-input"
                                                                         onChange={e => this.SqFeet = e.target.value}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-phoneno-input">Building Type</label>
                                                                        <select className="form-select" aria-label="Default select example">
                                                                            <option selected="">Select Type</option>
                                                                            <option value="1">Flat</option>
                                                                            <option value="2">Villa</option>
                                                                            <option value="3">Office</option>
                                                                            </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-email-input">Location</label>
                                                                        <input type="email" className="form-control" id="basicpill-email-input"
                                                                         onChange={e => this.Location = e.target.value}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-address-input">Address</label>
                                                                        <textarea id="basicpill-address-input" className="form-control" rows="2"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>

                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                            <li className="next"><a href="#">Company Doct <i className="mdi mdi-arrow-right ms-1"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="tab-pane" id="company-document">
                                                      <div>
                                                        <div >
            
                                                            <h4 className="header-title">Uploads</h4>
                                                            <p className="card-title-desc">Upload images and douments
                                                            </p>
                            
                                                            <div>
                                                                <form action="#" className="dropzone">
                                                                    <div className="fallback">
                                                                        <input name="file" type="file" multiple="multiple"/>
                                                                    </div>
                                                                    <div className="dz-message needsclick">
                                                                        <div className="mb-3">
                                                                            <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i>
                                                                        </div>
                                                                        
                                                                        <h4>Drop files here to upload</h4>
                                                                    </div>
                                                                </form>
                                                            </div>
                            
                                                            <div className="text-center mt-4">
                                                                <button type="button" className="btn btn-primary waves-effect waves-light">Send Files</button>
                                                            </div>
                                                        </div>

                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">

                                                            <li className="previous"> <Link to="#"><i className="mdi mdi-arrow-left me-1"></i> Seller Details</Link></li>
                                                            <li className="next"> <Link to="#">Bank Details <i className="mdi mdi-arrow-right ms-1"></i></Link></li>
                                                        </ul>

                                                      </div>
                                                    </div>
                                                    <div className="tab-pane" id="bank-detail">
                                                        <div>
                                                          <form>
                                                              
                                                             
                                                             
                                                          </form>

                                                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                            <li className="previous"> <Link to="#"><i className="mdi mdi-arrow-left me-1"></i> Company Doct</Link></li>
                                                            <li className="next"> <Link to="#">Confirm Details <i className="mdi mdi-arrow-right ms-1"></i></Link></li>
                                                        </ul>
                                                        </div>
                                                      </div>
                                                    <div className="tab-pane" id="confirm-detail">
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-6">
                                                                <div className="text-center">
                                                                    <div className="mb-4">
                                                                        <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                                                    </div>
                                                                    <div>
                                                                        <h5>Confirm Detail</h5>
                                                                        <p className="text-muted">If several languages coalesce, the grammar of the resulting</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                            <li className="previous"> <Link to="#"><i className="mdi mdi-arrow-left me-1"></i> Bank Details</Link></li>
                                                            <li className="float-end"> <Link to="#">Confirm <i className="mdi mdi-arrow-right ms-1"></i></Link></li>
                                                        </ul>
                                                    </div>
                                                    
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                         

                        </div>
        
                        
                    </div> 
                </div>
               
        </div>
       
        <div className="rightbar-overlay"></div>
        </div>
        </div>
    }
}
export default Addbuilding;

       
