import React, {Component} from 'react';
import axios from 'axios';
import AuthService from "../services/auth.service";
import {Link, withRouter, Redirect} from 'react-router-dom';
class Addplot extends React.Component {
    constructor(){
        super();
         this.logOut = this.logOut.bind(this);
        this.state = {
            roll : [],
              currentUser: undefined,
        }
    }
    componentDidMount(){
         const user = AuthService.getCurrentUser();
         if (!user) this.setState({ redirect: "/auth-login.html" });
         
         if (user) {
           this.setState({
             currentUser: user.full_name
           });
         }
            axios.get(process.env.REACT_APP_API_URL+'api/resource/Land?fields=[%22land%22,%22land_owner_name%22,%22name%22]', {
                headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({roll:response.data['data']})
            })
        } 
    handleSubmit = e => {
        alert("success")
        e.preventDefault();
        const buildingData= {
            building_name:this.BuildingName,
            location:this.Location,
            building_type:this.BuildingType,
            area:this.Area,
            sq_feet:this.SqFeet,
            land:this.Land,
            address:this.Address,
            
        }

        axios.post(process.env.REACT_APP_API_URL+'api/resource/Buildings', buildingData,{
        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
        'content-type': 'application/json'}
    })                
        .then( 
            res =>{ console.log(res) }
        )
        .catch(
            err =>{ console.log(err)}
        )
    }
   logOut = e => {
    e.preventDefault();
    AuthService.logout()
    const { history } = this.props;
          this.props.history.push("/auth-login.html");
          window.location.reload();
    }
    render() {
         if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
      return <div> 
    <div id="layout-wrapper">
            <div className="main-content">
                <div className="page-content">
                
                    <div className="page-title-box">
                        <div className="container-fluid">
                         <div className="row align-items-center">
                             <div className="col-sm-6">
                                 <div className="page-title">
                                     <h4>Add Plot</h4>
                                         <ol className="breadcrumb m-0">
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Add Plot</a></li>
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Plot</a></li>
                                             <li className="breadcrumb-item active">Add Building</li>
                                         </ol>
                                 </div>
                             </div>
                             <div className="col-sm-6">
                                <div className="float-end d-none d-sm-block">
                                </div>
                             </div>
                         </div>
                        </div>
                     </div>
                 
                    <div className="container-fluid">

                        <div className="page-content-wrapper">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Plot Details</h4>
    
                                            <div>
                                                    
                                                <div className="">
                                                    <div className="" id="seller-details">
                                                        <form onSubmit={this.handleSubmit}>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-firstname-input">Plot No</label>
                                                                        <input type="text" className="form-control" id="basicpill-firstname-input"
                                                                         onChange={e => this.BuildingName = e.target.value}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-lastname-input">Land</label>
                                                                        <select className="form-select" aria-label="Default select example"
                                                                         onChange={e => this.Land = e.target.value}>
                                                                            <option selected="">Select land</option>
                                                                            {this.state.roll.map((itm,k)=>{
                                                                         return(
                                                                            <option value={itm.name} >{itm.land}</option>)
                                                                        })}
                                                                           
                                                                            </select>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-phoneno-input">Area</label>
                                                                        <input type="text" className="form-control" id="basicpill-phoneno-input"
                                                                         onChange={e => this.Area = e.target.value}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-email-input">Sq. Feet</label>
                                                                        <input type="text" className="form-control" id="basicpill-email-input"
                                                                         onChange={e => this.SqFeet = e.target.value}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-phoneno-input">Building Type</label>
                                                                        <select className="form-select" aria-label="Default select example" 
                                                                        onChange={e => this.BuildingType = e.target.value}>
                                                                            <option selected="">Select Type</option>
                                                                            <option value="Flat">Flat</option>
                                                                            <option value="Villa">Villa</option>
                                                                            <option value="House">House</option>
                                                                            <option value="Commercial">Commercial</option>
                                                                            </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-email-input">Location</label>
                                                                        <input type="text" className="form-control" id="basicpill-email-input"
                                                                         onChange={e => this.Location = e.target.value}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="basicpill-address-input">Address</label>
                                                                        <textarea id="basicpill-address-input" className="form-control" rows="2"
                                                                         onChange={e => this.Address = e.target.value}></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        

                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                            <li className="next"><button type="submit">submit<i className="mdi mdi-arrow-right ms-1"></i></button></li>
                                                        </ul>
                                                        </form>
                                                    </div>
                                                    
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                            </div>

                        </div>
        
                        
                    </div> 
                </div>
               
        </div>
       
        <div className="rightbar-overlay"></div>
        </div>
        </div>
    }
}
export default withRouter(Addplot);

       
