import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Checkbox from '../components/Checkbox';
class Paymentpending extends React.Component {

//<<<
    constructor() {
        super();
        this.state = {
            build:[],
          builder:'',
             tablerow:[] ,total: 0       };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleformSubmit = this.handleformSubmit.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this); 
                       }
// >>>


    //<<<
    componentDidMount(){
        axios.get( encodeURI(process.env.REACT_APP_API_URL+'api/resource/Buildings?fields=["builder","builder_name"]'), {
        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
        'content-type': 'application/json'}
                  })
            .then(response=>{
                 this.setState({build:response.data['data']})
                 console.log({build:response.data['data']})
                  
                })
                     }
    //>>> 

     //<<<   
    handleChange(event) 
     {
        this.setState({builder: event.target.value});
      }
    //>>>
        
      //<<<
      handleSubmit(event) {
        


        axios.get( encodeURI(process.env.REACT_APP_API_URL+'api/method/dnb.api.get_payment_task?builder='+this.state.builder), {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
                      })
                .then(response=>{
                     this.setState({tablerow:response.data['message']})
                     console.log({tablerow:response.data['message']}) 
                    })
                         

        console.log(this.state);
        event.preventDefault();
                          }
      //>>>
      handleformSubmit = e => { 
        alert("success")
        e.preventDefault();
        const paymentsData= {
           total:this.state.total,
           recipt:this.RecieptNo,
            payment_date:this.Date,
            builder:this.state.builder
            
        }

        axios.post(process.env.REACT_APP_API_URL+'api/resource/Payments',paymentsData,{
        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
        'content-type': 'application/json'}
    })                
        .then( 
            res =>{ console.log(res) }
        )
        .catch(
            err =>{ console.log(err)}
        )
    }
      calculateTotal(input, checked) {
        let { total } = this.state;
    
        if (checked) {
          total += input;
        } else {
          total -= input;
        }
    
        this.setState({
          total
        });
      }

    render() {
       // let sum=0
      return <div>
        <div id="layout-wrapper">
            
            <div class="main-content">

                <div class="page-content">

                    {/* <!-- start page title --> */}
                    <div class="page-title-box">
                        <div class="container-fluid">
                         <div class="row align-items-center">
                             <div class="col-sm-6">
                                 <div class="page-title">
                                     <h4>Building Details</h4>
                                         <ol class="breadcrumb m-0">
                                             <li class="breadcrumb-item"><a href="javascript: void(0);">Building Details</a></li>
                                             <li class="breadcrumb-item"><a href="javascript: void(0);">Building</a></li>
                                             <li class="breadcrumb-item active">Building Details</li>
                                         </ol>
                                 </div>
                             </div>
                             <div class="col-sm-6">
                                <div class="float-end d-none d-sm-block">
                                    <a href="" class="btn btn-success">Add Building</a>
                                </div>
                             </div>
                         </div>
                        </div>
                     </div>  
                     <div class="container-fluid">
                        <div class="page-content-wrapper">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                
                                                
                                                    <div class="mt-4 mt-xl-3">
                                                        <a href="#" class="text-primary">Residential</a>
                                                        <h5 class="mt-1 mb-3">Pending Payments Of</h5>
    
                                                        
                                                        
                                                        <h5 class="mt-2">Builders;</h5>
                                              
                                                        <hr class="my-4"/>

                                                        <div class="mt-4">

                                                          <div class="mt-4">
                    <form onSubmit={this.handleSubmit}> 
                            <div className="modal-body">
                                 <p class="text-muted mb-2"><b>Builder Name</b> </p>
                                    
                                 <select className="form-select" aria-label="Default select example" value={this.state.builder}  onChange={this.handleChange}>
                                <option selected="">status type</option>

                                        {this.state.build.map((itm,k)=>{
                                                  return( 
                                        <option value={itm.builder}>{itm.builder_name}</option>
                                        )
                                    })}
                                    </select>
                            </div>
                            <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary waves-effect waves-light">submit</button>
                            </div>
                    </form>
                             </div>
                                                        </div>  
                                                           
                                                    </div>
                                                
                                            </div>
                                           {/*  <!-- end row --> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="header-title mb-4">Pending Payouts </h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                        <thead className="thead-light">
                                                                        <tr><th>Plot NO</th>
                                                                            <th>Task</th>
                                                                            <th>Amount</th>
                                                                            <th>check</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.state.tablerow.map((itm,k)=>{
                                                                       //  sum=sum+itm.weekly_payout
                                                  return( 
                                                                        <tr>
                                                                            <td><Link to={process.env.PUBLIC_URL +'/view-building-detail/'+itm.building_name}>{itm.plot}</Link></td>
                                                                            <td>{itm.task_display}</td>
                                                                            <td>{itm.weekly_payout}</td>
                                                                            <td>
                                                                            <Checkbox calculateTotal={this.calculateTotal} value={itm.weekly_payout}/>
                                                                            </td>
                                                                        </tr>

                                                                    )
                                                                    })}


                                                                        
                                                                    </tbody>
                                                                </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                    <div className="card">
                                    <div className="card-body">
                                    <form onSubmit={this.handleformSubmit}>
                                        <div class="row">
                                        <div class="col-md-12 d-inline mb-3 ">
                                        <h4 className="header-title mb-4 d-inline">Total Amount Of Payouts: </h4>
                                          <p className="text-muted d-inline"><b>&nbsp;{this.state.total}&nbsp;₹ </b> </p>
                                        </div>
                                        <hr className=""/>
                                            <div class="col-md-4"> 
                                                <div class="md-3"> 
                                                    <label class="checkbox-inline"><b>Total Amount:</b><input type="text" 
                                                    onChange={e => this.state.total = e.target.value}/> </label> 
                                                </div>
                                            </div> 
                                            <div class="col-md-4"> 
                                                <div class="md-3">   
                                                    <label class="checkbox-inline"><b>Reciept No:</b><input type="text" 
                                                    onChange={e => this.RecieptNo = e.target.value}/> </label>
                                                </div>   
                                            </div> 
                                            <div class="col-md-4"> 
                                                <div class="md-3">    
                                                    <label class="checkbox-inline"><b>Date:</b><input id="example-date-input"  type="date" 
                                                    onChange={e => this.Date = e.target.value}/> </label>
                                                </div> 
                                            </div> 
                                            <div className="modal-footer">
                                             <button type="submit" className="btn btn-primary waves-effect waves-light"onChange={e => this.state.builder= e.target.value}>submit</button>
                                             </div>
                                        </div>
                                    </form>
                                     </div>
                                     </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
 }
}
export default Paymentpending;
