
import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
class Lands extends React.Component {
    constructor(){
        super();
        this.state = {
            land : []
        }
    }
    componentDidMount(){
    axios.get( encodeURI(process.env.REACT_APP_API_URL+'api/resource/Land?fields=["land","location","land_owner_name","area","address","land_user"]'), {
        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
        'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({land:response.data['data']})
        console.log({land:response.data['data']})
    })
    }
    render() {
      return <div> 
        <div id="layout-wrapper">
            
            <div className="main-content">

                <div className="page-content">
                    <div className="page-title-box">
                        <div className="container-fluid">
                         <div className="row align-items-center">
                             <div className="col-sm-6">
                                 <div className="page-title">
                                     <h4>Land List</h4>
                                         <ol className="breadcrumb m-0">
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Lands</a></li>
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Lands</a></li>
                                             <li className="breadcrumb-item active">Land List</li>
                                         </ol>
                                 </div>
                             </div>
                             <div className="col-sm-6">
                                <div className="float-end d-none d-sm-block">
                                    <Link to="" className="btn btn-success">Add Land</Link>
                                </div>
                             </div>
                         </div>
                        </div>
                     </div>




                    <div className="container-fluid">

                        <div className="page-content-wrapper">

           
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
            
                                            <h4 className="header-title">Land</h4>
                                            <p className="card-title-desc"></p>
            
                                            <table id="datatable-buttons" className="table table-striped table-bordered dt-responsive nowrap" style={{width : "100%"}}>
                                                <thead>
                                                <tr>
                                                    <th>Land</th>
                                                    <th>Location</th>
                                                    <th>Land Owner Name</th>
                                                    <th>Area</th>
                                                    <th>Address</th>
                                                    <th>Land User </th>
                                                </tr>
                                                </thead>
            
            
                                                <tbody>
                                                {this.state.land.map((itm,k)=>{
                                                  return(   
                                                <tr>
                                                    <td><Link to={process.env.PUBLIC_URL +"/land-details.html"}>{itm.land}</Link></td>
                                                    <td>{itm.location}</td>
                                                    <td>{itm.land_owner_name}</td>
                                                    <td>{itm.area}</td>
                                                    <td>{itm.address}</td>
                                                    <td>{itm.land_user}</td>
                                                </tr>)
                                                 })}
                                                
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> 
                            </div> 

                        </div>
        
                        
                    </div> 
                </div>
        

              
                
            </div>
           

        
      
        <div className="rightbar-overlay"></div>

      </div> 
    </div>
    }
}
export default Lands;
