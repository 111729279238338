import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';                      
class Paymentdetails extends React.Component { 
    constructor() {
        super();
        this.state = {
            pay:[] 
        }
    }
        componentDidMount(){
            
       axios.get( encodeURI(process.env.REACT_APP_API_URL+'api/resource/Payments?fields=["recipt","builder_name","total","payment_date","builder"]'), {
           headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
           'content-type': 'application/json'}
       })
    
       .then(response=>{
           this.setState({pay:response.data['data']})
           console.log({pay:response.data['data']})
         
       })
       } 
    

    render() {
       return <div>                           
        <div id="layout-wrapper">
            
            <div className="main-content">

                <div className="page-content">
                    <div className="page-title-box">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <div className="page-title">
                                        <h4>Payment Details</h4>
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Buildings</a></li>
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Buildings</a></li>
                                                <li className="breadcrumb-item active">Buildings List</li>
                                            </ol>
                                    </div>
                                </div>
                            <div className="col-sm-6">
                                    <div className="float-end d-none d-sm-block">
                                        <Link to="" className="btn btn-success">Add Building</Link>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>



                    
                    <div className="container-fluid">
                        <div className="page-content-wrapper">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                                <h4 className="header-title">Buildings</h4>       
                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap mb-0">
                                                    <thead className="thead-light">
                                                                <tr>
                                                                    <th>Reciept</th>
                                                                    <th>Builder Name</th>
                                                                    <th>Total Amount</th>
                                                                    <th>Date</th> 
                                                                </tr>
                                                    </thead>
                                                    <tbody>
                                                                {this.state.pay.map((itm,k)=>{
                                                                return(   
                                                                <tr>
                                                                    <td>{itm.recipt}</td>
                                                                    <td>{itm.builder_name}</td>
                                                                    <td>{itm.total}</td>
                                                                    <td>{itm.payment_date}</td>
                                                                </tr>)
                                                                })}
                                                                
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                        </div>                  
                    </div> 
                </div>
        

              
                
            </div>
           

        
      
        <div className="rightbar-overlay"></div>

      </div>
                            </div>
 }
}
export default Paymentdetails;