import React, {Component} from 'react';
import axios from 'axios';
import AuthService from "../services/auth.service";
import {Link, withRouter, Redirect} from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";
import Form from '../components/Form'
import Modalbuilding from '../components/Modalbuilding';
import Addtask from '../components/Addtask';
class Viewbuilding extends React.Component {
    constructor(props){
        super(props);
         this.logOut = this.logOut.bind(this);
        this.state = {
            building:[], tasks:[],
            BuildingName : [],Location : [],BuildingType : [],Area : [],Sqfeet : [],Land : [],Address : [], img : [], bld: [],name:[],stage:[],Tno:[],
            currentUser: undefined,
        }
    }
    async componentDidMount(){
         const user = AuthService.getCurrentUser();
         if (!user) this.setState({ redirect: "/auth-login.html" });
         if (user) {
                this.setState({
                    currentUser: user.full_name
                });
            }
            const id = this.props.match.params.slug2;
            const USR_TOKEN = localStorage.getItem("userToken")
            if(USR_TOKEN){
                    axios.get(process.env.REACT_APP_API_URL + '/api/resource/Buildings/' + id, {
                        headers : {'Authorization' : localStorage.getItem("userToken"), 
                        'content-type': 'application/json'}
                    })
                    .then(response=>{
                       // console.log(response.data['data'])
                        this.setState({building:response.data['data']})
                        this.setState({tasks:response.data['data']['tasks']})
                        this.setState({bld:response.data['data']['tasks']})
                        this.setState({stage:this.state.tasks.length})
                        this.setState({Tno:this.state.tasks[(this.state.stage-1)]['serial_no']})
                       // console.log(response.data['data']['tasks'].length)

                    })
            }
        } 
    handleUpdate = () => {
        const id = this.props.match.params.slug2;
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
                axios.get(process.env.REACT_APP_API_URL + '/api/resource/Buildings/' + id, {
                    headers : {'Authorization' : localStorage.getItem("userToken"), 
                    'content-type': 'application/json'}
                })
                .then(response=>{
                   // console.log(response.data['data'])
                    this.setState({building:response.data['data']})
                    this.setState({tasks:response.data['data']['tasks']})

                    this.setState({bld:response.data['data']['tasks']})

                })
        }
    }
    showImage(img1, img2, img3, img4, img5){
        let img =[]
        
    }
    
     logOut = e => {
        e.preventDefault();
        AuthService.logout()
        const { history } = this.props;
              this.props.history.push("/auth-login.html");
              window.location.reload();
      }
    render() {
         if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        //let stage=this.state.tasks
        /* let Tno=''
        Tno=this.state.tasks[(this.state.tasks.length-1)]['serial_no'] */
      return <div>
       
       <div id="layout-wrapper">
            <div className="main-content">
                <div className="page-content">               
                    <div className="page-title-box">
                        <div className="container-fluid">
                         <div className="row align-items-center">
                             <div className="col-sm-6">
                                 <div className="page-title">
                                     <h4>Building Details</h4>
                                         <ol className="breadcrumb m-0">
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Building Details</a></li>
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Building</a></li>
                                             <li className="breadcrumb-item active">Building Details</li>
                                         </ol>
                                 </div>
                             </div>
                             <div className="col-sm-6">
                                <div className="float-end d-none d-sm-block">
                                   <Link to="" className="btn btn-success">Add Building</Link>
                                </div>
                             </div>
                         </div>
                        </div>
                     </div>

                    <div className="container-fluid">

                        <div className="page-content-wrapper">

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-4">
                                                    <div className="product-detail">
                                                                <div className="tab-content" id="v-pills-tabContent">
                                                                    <div className="tab-pane fade show active" id="product-1" role="tabpanel">
                                                                        <div className="product-img">
                                                                            <img src={process.env.REACT_APP_API_URL +this.state.building['image']} alt="" className="img-fluid mx-auto d-block" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-8">
                                                    <div className="mt-4 mt-xl-3">
                                                         <Link to="#" className="text-primary">{this.state.building['building_type']}</Link>
                                                        <h5 className="mt-1 mb-3">{this.state.building['building_name']}</h5>
                                              
                                                        <hr className="my-4"/>

                                                        <div className="mt-4">

                                                          <div className="mt-4">
                                                            <p className="text-muted mb-2"><b>Location: </b>{this.state.building['location']}</p>
                                                            <p className="text-muted mb-2"><b>Building Type: </b>{this.state.building['building_type']}</p>
                                                            <p className="text-muted mb-2"><b>Area: </b> {this.state.building['area']}</p>
                                                            <p className="text-muted mb-2"><b>Sq. Feet: </b>{this.state.building['sq_feet']}</p>
                                                            <p className="text-muted mb-2"><b>No. Of Stage: </b>{this.state.Tno}</p>
                                                            <p className="text-muted mb-2"><b>Land: </b>{this.state.building['land_name']}</p>
                                                            <p className="text-muted mb-2"><b>Address: </b>{this.state.building['address']}</p>
                                                            
                                                          </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                
                                            </div>
                                           
                                        </div>
                                    </div>
                                    
    
                                           <div className="card">
                                               <div className="card-body">
                                                    <h4 className="header-title mb-4">STAGE:</h4>
                                                    <div className="d-inline-flex mb-3">
                                                        <div className="text-muted"><p><strong>Add Task-</strong></p>
                                                                <button type="button" class="btn btn-primary btn-sm waves-effect waves-light float-sm-right" data-bs-toggle="modal" data-bs-target="#myModal">Add Task</button>
                                                                <Addtask updateState={this.handleUpdate}  name={this.state.building['name']}/>
                                                        </div>
                                                    </div>
                                                    <div className="border p-4 rounded">
                                                    {this.state.tasks.map((itm,k)=>{
                                                        return(
                                                        <div className="media border-bottom pb-3">
                                                        
                                                            <div className="media-body">
                                                           
                                                                <p className="text-muted mb-2">#{k + 1}. <b>{itm.task_display}</b></p>
                                                                 {(() => {
                                                                    switch (itm.status) {
                                                                    case "Pending":   return (<h5 className="badge rounded-pill bg-danger ">{itm.status}</h5>);
                                                                    break;
                                                                    case "On Progress":   return (<h5 className="badge rounded-pill bg-warning ">{itm.status}</h5>);
                                                                    break;
                                                                    case "Task Finished":   return (<h5 className="badge rounded-pill bg-info ">{itm.status}</h5>);
                                                                    break;
                                                                    case "Payment Pending":   return (<h5 className="badge rounded-pill bg-primary ">{itm.status}</h5>);
                                                                    break;
                                                                    case "Paid":   return (<h5 className="badge rounded-pill bg-success ">{itm.status}</h5>);
                                                                    break;
                                                                    }
                                                                })()}
                                                                
       
                                                                <ul className="list-inline product-review-link mb-0">
                                                                <SimpleReactLightbox> 
                                                                <SRLWrapper>
                                                                    <li className="list-inline-item">
                                                                       {(() => {
                                                                         if(itm.photo_1) { 
                                                                            return( <div className="mt-4">
                                                                            <img src={process.env.REACT_APP_API_URL+itm.photo_1} alt="" className="rounded avatar-sm"/>
                                                                            </div> );
                                                                            }
                                                                         else {
                                                                         return ""
                                                                        }
                                                                        })()}
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                       {(() => {
                                                                         if(itm.photo_2) { 
                                                                            return( <div className="mt-4">
                                                                            <img src={process.env.REACT_APP_API_URL+itm.photo_2} alt="" className="rounded avatar-sm"/>
                                                                            </div> );
                                                                            }
                                                                         else {
                                                                         return ""
                                                                        }
                                                                        })()}
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                       {(() => {
                                                                         if(itm.photo_3) { 
                                                                            return( <div className="mt-4">
                                                                            <img src={process.env.REACT_APP_API_URL+itm.photo_3} alt="" className="rounded avatar-sm"/>
                                                                            </div> );
                                                                            }
                                                                         else {
                                                                         return ""
                                                                        }
                                                                        })()}
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                       {(() => {
                                                                         if(itm.photo_4) { 
                                                                            return( <div className="mt-4">
                                                                            <img src={process.env.REACT_APP_API_URL+itm.photo_4} alt="" className="rounded avatar-sm"/>
                                                                            </div> );
                                                                            }
                                                                         else {
                                                                         return ""
                                                                        }
                                                                        })()}
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                       {(() => {
                                                                         if(itm.photo_5) { 
                                                                            return( <div className="mt-4">
                                                                            <img src={process.env.REACT_APP_API_URL+itm.photo_5} alt="" className="rounded avatar-sm"/>
                                                                            </div> );
                                                                            }
                                                                         else {
                                                                         return ""
                                                                        }
                                                                        })()}
                                                                    </li>
                                                                    </SRLWrapper>
                                                                    </SimpleReactLightbox>
                                                                </ul>
                                                               
                                                            </div>
                                                            <div>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                       <p className="float-sm-right font-size-12"><strong>{itm.days} days ({itm.weekly_payout})</strong></p>
                                                                   </td>
                                                               </tr>
                                                             <tr>
                                                        
                                                        <td>
                                                           
                                                            <button type="button" className="btn btn-primary btn-sm waves-effect waves-light" data-bs-toggle="modal" data-bs-target={".edit" + k + "-modal-lg"}>Edit</button>
                                                        
                                                            <Modalbuilding updateState={this.handleUpdate} k={k} itm={itm} name={itm.name}/>
                                                            
                                                        </td>
                                                    </tr>
                                                    </table>
                                                            </div>
                                                        </div>)



                                                      })} 


                                                        
                                                        
                                                    </div>
                                               </div>
                                           </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                           
    
                            

                        </div>
        
                        
                    </div>
        </div>
     </div>
}
}
export default withRouter(Viewbuilding);
 




