import React, {Component} from 'react';
import axios from 'axios';
import AuthService from "../services/auth.service";
import {Link, withRouter, Redirect} from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

import Form from '../components/Form'
import FileUpload from '../components/FileUpload'

class Bview extends React.Component {
    constructor(){
        super();
         this.logOut = this.logOut.bind(this);
        this.state = {
            BuildingName : [],Location : [],BuildingType : [],Area : [],Sqfeet : [],Land : [],Address : [], img : [], bld: [],stage:[],Tno:[],
            currentUser: undefined,
        }
    }
    componentDidMount(){
         const user = AuthService.getCurrentUser();
         if (!user) this.setState({ redirect: "/auth-login.html" });
         
         if (user) {
           this.setState({
             currentUser: user.full_name
           });
         }
            const id = this.props.match.params.slug3;
            axios.get(process.env.REACT_APP_API_URL+'api/resource/Buildings/' +id, {
                headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({BuildingName:response.data.data['building_name']})
                this.setState({BuildingType:response.data.data['building_type']})
                this.setState({Location:response.data.data['location']})
                this.setState({Area:response.data.data['area']})
                this.setState({Sqfeet:response.data.data['sq_feet']})
                this.setState({Land:response.data.data['land_name']})
                this.setState({Address:response.data.data['address']})
                this.setState({img:response.data.data['image']})

                this.setState({bld:response.data['data']['tasks']})
                this.setState({stage:this.state.bld.length})
                this.setState({Tno:this.state.bld[(this.state.stage-1)]['serial_no']})
               // console.log(response.data['data']['tasks'].length)
                console.log(response.data['data']['tasks'])
            })
        } 
    showImage(img1, img2, img3, img4, img5){
        let img =[]
        
    }
    handleSubmit = e => {
        alert("success")
        const editData= {
            status:this.StatusType,
           
            
        }
       console.log(editData)
        axios.post(encodeURI(process.env.REACT_APP_API_URL+'api/resource/Building Task Items'), editData,{
        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
        'content-type': 'application/json'}
    })                
        .then( 
            res =>{ console.log(res) }
        )
        .catch(
            err =>{ console.log(err)}
        )
    }
     logOut = e => {
        e.preventDefault();
        AuthService.logout()
        const { history } = this.props;
              this.props.history.push("/auth-login.html");
              window.location.reload();
      }
    render() {
         if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
      return <div>
       
       <div id="layout-wrapper">
            <div className="main-content">

                <div className="page-content">

                  
                    <div className="page-title-box">
                        <div className="container-fluid">
                         <div className="row align-items-center">
                             <div className="col-sm-6">
                                 <div className="page-title">
                                     <h4>Building Details</h4>
                                         <ol className="breadcrumb m-0">
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Building Details</a></li>
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Building</a></li>
                                             <li className="breadcrumb-item active">Building Details</li>
                                         </ol>
                                 </div>
                             </div>
                             <div className="col-sm-6">
                                <div className="float-end d-none d-sm-block">
                                   <Link to="" className="btn btn-success">Add Building</Link>
                                </div>
                             </div>
                         </div>
                        </div>
                     </div>
                    


                    <div className="container-fluid">

                        <div className="page-content-wrapper">

                        


                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-5">
                                                    <div className="product-detail">
                                                                <div className="tab-content" id="v-pills-tabContent">
                                                                    <div className="tab-pane fade show active" id="product-1" role="tabpanel">
                                                                        <div className="product-img">
                                                                            <img src={'https://erp.acemep.com/'+this.state.img} alt="" className="img-fluid mx-auto d-block" data-zoom="assets/images/product/img-1.png"/>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>

                                                    </div>
                                                  
                                                </div>
                                                <div className="col-xl-7">
                                                    <div className="mt-4 mt-xl-3">
                                                         <Link to="#" className="text-primary">Residential</Link>
                                                        <h5 className="mt-1 mb-3">{this.state.BuildingName}</h5>
                                              
                                                        <hr className="my-4"/>

                                                        <div className="mt-4">

                                                          <div className="mt-4">
                                                            <p className="text-muted mb-2"><b>Location: </b>{this.state.Location}</p>
                                                            <p className="text-muted mb-2"><b>Building Type: </b>{this.state.BuildingType}</p>
                                                            <p className="text-muted mb-2"><b>Area: </b> {this.state.Area}</p>
                                                            <p className="text-muted mb-2"><b>Sq. Feet: </b>{this.state.Sqfeet}</p>
                                                            <p className="text-muted mb-2"><b>No. Of Stage: </b>{this.state.Tno}</p>
                                                            <p className="text-muted mb-2"><b>Land: </b>{this.state.Land}</p>
                                                            <p className="text-muted mb-2"><b>Address: </b>{this.state.Address}</p>
                                                          </div>
                                                        </div>

                                                                                                             
    
                                                        
    
                                                      
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
    
                                          


                                           <div className="card">
                                               <div className="card-body">
                                                    <h4 className="header-title mb-4">STAGE:</h4>
                                                    <div className="d-inline-flex ">
                                                       
                                                        <div className="text-muted"></div>
                                                    </div>
                                                    <div className="border p-4 rounded">


                                                    {this.state.bld.map((itm,k)=>{
                                                        return(
                                                        <div className="media border-bottom pb-3">
                                                        
                                                            <div className="media-body">
                                                           
                                                                <p className="text-muted mb-2 mt-2"><b>{itm.task_display}</b></p>
                                                                 {(() => {
                                                                    switch (itm.status) {
                                                                    case "Pending":   return (<h5 className="badge rounded-pill bg-danger ">{itm.status}</h5>);
                                                                    break;
                                                                    case "On Progress":   return (<h5 className="badge rounded-pill bg-warning ">{itm.status}</h5>);
                                                                    break;
                                                                    case "Pending Approval":   return (<h5 className="badge rounded-pill bg-info ">{itm.status}</h5>);
                                                                    break;
                                                                    case "Payment Pending":   return (<h5 className="badge rounded-pill bg-primary ">{itm.status}</h5>);
                                                                    break;
                                                                    case "Paid":   return (<h5 className="badge rounded-pill bg-success ">{itm.status}</h5>);
                                                                    break;
                                                                    }
                                                                })()}
                                                                
       
                                                                <ul className="list-inline product-review-link mb-0">
                                                                <SimpleReactLightbox> 
                                                                <SRLWrapper>
                                                                    <li className="list-inline-item">
                                                                       {(() => {
                                                                         if(itm.photo_1) { 
                                                                            return( <div className="mt-4">
                                                                            <img src={'https://erp.acemep.com/'+itm.photo_1} alt="" className="rounded avatar-sm"/>
                                                                            </div> );
                                                                            }
                                                                         else {
                                                                         return ""
                                                                        }
                                                                        })()}
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                       {(() => {
                                                                         if(itm.photo_2) { 
                                                                            return( <div className="mt-4">
                                                                            <img src={'https://erp.acemep.com/'+itm.photo_2} alt="" className="rounded avatar-sm"/>
                                                                            </div> );
                                                                            }
                                                                         else {
                                                                         return ""
                                                                        }
                                                                        })()}
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                       {(() => {
                                                                         if(itm.photo_3) { 
                                                                            return( <div className="mt-4">
                                                                            <img src={'https://erp.acemep.com/'+itm.photo_3} alt="" className="rounded avatar-sm"/>
                                                                            </div> );
                                                                            }
                                                                         else {
                                                                         return ""
                                                                        }
                                                                        })()}
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                       {(() => {
                                                                         if(itm.photo_4) { 
                                                                            return( <div className="mt-4">
                                                                            <img src={'https://erp.acemep.com/'+itm.photo_4} alt="" className="rounded avatar-sm"/>
                                                                            </div> );
                                                                            }
                                                                         else {
                                                                         return ""
                                                                        }
                                                                        })()}
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                       {(() => {
                                                                         if(itm.photo_5) { 
                                                                            return( <div className="mt-4">
                                                                            <img src={'https://erp.acemep.com/'+itm.photo_5} alt="" className="rounded avatar-sm"/>
                                                                            </div> );
                                                                            }
                                                                         else {
                                                                         return ""
                                                                        }
                                                                        })()}
                                                                    </li>
                                                                    </SRLWrapper>
                                                                    </SimpleReactLightbox>
                                                                </ul>
                                                               
                                                            </div>
                                                            <div>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                       <p className="float-sm-right font-size-12 mt-3"><strong>{itm.days} days ({itm.weekly_payout})</strong></p>
                                                                   </td>
                                                               </tr>
                                                             
                                                               {/*  <tr>
                                                                   <td>
                                                                   <button type="button" className="btn btn-primary btn-sm waves-effect waves-light" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">Edit</button>
                                                        
                                                            
                                                        <div className="modal fade bs-example-modal-lg show" tabindex="-1" aria-labelledby="myLargeModalLabel" style= {{paddingright: "17px"}} aria-modal="true" role="dialog">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title mt-0" id="myLargeModalLabel">{itm.task_display}</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                                                            
                                                                        </button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                       <Form/>
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                                   </td>
                                                                </tr> */}
                                                            </table>
                                                            </div>
                                                        </div>)



                                                      })} 


                                                        
                                                        
                                                    </div>
                                               </div>
                                           </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                           
    
                            

                        </div>
        
                        
                    </div>
        </div>
     </div>
}
}
export default withRouter(Bview);
 




