import React, {Component} from 'react';
import {Link, withRouter, Redirect} from 'react-router-dom';
import axios from 'axios';

import Form from './Form'
import FileUpload from './FileUpload'

class Modalbuilding  extends React.Component {
    constructor(props){
        super(props);
    }

    handleSubmit = e => {
        e.preventDefault()
       // alert(this.props.name)
        const editData= {
            status:this.StatusType,
            name:this.props.name
            
        }
       console.log(editData)
        axios.post(encodeURI(process.env.REACT_APP_API_URL+'api/method/dnb.api.update_task_status'), editData,{
            headers : {'Authorization' : localStorage.getItem("userToken"), 
            'content-type': 'application/json'}
        })                
        .then( 
            res =>{ console.log(res) 
                this.props.updateState() }
        )
        .catch(
            err =>{ console.log(err)}
        )
    }
    handleUpdate = () => {
        this.props.updateState()
    }
    render() {

        return(
            <div className={"modal fade edit"+ this.props.k +"-modal-lg show"} tabindex="-1" aria-labelledby="myLargeModalLabel" style= {{paddingright: "17px"}} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">{this.props.itm.task_display}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            
                        </button>
                    </div>
                    <div className="modal-body">
                       <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                            <form onSubmit={this.handleSubmit}>                         
                                                    <select className="form-select" aria-label="Default select example"
                                                    onChange={e => this.StatusType = e.target.value }>
                                                        <option value="Pending">Pending</option>
                                                        <option value="On Progress">On Progress</option>  
                                                        <option value="Task Finished">Task Finished</option>  
                                                    </select>
                                                <div className="mb-3 position-relative mt-4">
                                                        <button type="submit" className="btn btn-outline-primary waves-effect waves-light">Save</button>
                                                </div>   
                                            </form>
                                    </div>   
                                </div>
                            </div>
                        </div> 
                       <FileUpload no='1' docname={this.props.name}  updateState={this.handleUpdate} />
                       <FileUpload no='2' docname={this.props.name}  updateState={this.handleUpdate}/>
                       <FileUpload no='3'  docname={this.props.name} updateState={this.handleUpdate} />
                       <FileUpload no='4'  docname={this.props.name} updateState={this.handleUpdate}/>
                       <FileUpload no='5' docname={this.props.name} updateState={this.handleUpdate} />
                    </div>
                </div>
            </div>
        </div>

        )
        
    }
}

export default Modalbuilding;
