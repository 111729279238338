import React, {Component} from 'react';
import axios from 'axios';
import AuthService from "../services/auth.service";//step1 add 
import { BrowserRouter, Route, Link, withRouter, Redirect } from 'react-router-dom';//step2 add this withrouter module
class Home extends React.Component {
    constructor(){
        super();
        //step3 add first this on constructor
        this.logOut = this.logOut.bind(this);
        //
        this.state = {
            
            book : [],pay :[],
            
            //step4  add under the this.state
            currentUser: undefined,
            //
        }

    }
    componentDidMount(){
        
   
        //step5 add under the comp..nt()
        const user = AuthService.getCurrentUser();
        if (!user) this.setState({ redirect: "/auth-login.html" });//reddirect:"???" ??? is the default link
        
        if (user) {
          this.setState({
            currentUser: user.full_name,
            userid: user.uid
          });
        }
        //
        axios.get( encodeURI(process.env.REACT_APP_API_URL+'api/resource/Buildings?fields=["builder"]&filters=[["builder","=","'+user.uid+'"]]'), {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({book:response.data['data']})
            console.log({book:response.data['data']})
        
        })


        axios.get( encodeURI(process.env.REACT_APP_API_URL+'api/resource/Payments?fields=["recipt","builder","total","payment_date"]&filters=[["builder","=","'+user.uid+'"]]'), {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        
        })

        axios.get( encodeURI( process.env.REACT_APP_API_URL + '/api/resource/Payments?fields=["recipt","builder","total","payment_date"]&filters=[["builder","=","'+user.uid+'"]]'), {
                headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}
        })
        .then(response=>{
                this.setState({pay:response.data['data']})
                //console.warn({ currentUser: user.full_name})
        })


        
        AuthService.setKeys(user.uid);
        AuthService.showStorage();
    }
    //step6 add these funcion 
    logOut = e => {
        e.preventDefault();
        AuthService.logout()
        const { history } = this.props;
              this.props.history.push("/auth-login.html");//push("???")  ??? is the default link 
              window.location.reload();
      }
      //
    render() { 
        //step7 add this condition for redirect
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        //
//step8 give the return statement for condition all true
      return <div>

    <div id="layout-wrapper">
<div className="main-content">
<div className="page-content">
   {/*  <!-- start page title --> */}
    <div className="page-title-box">
        <div className="container-fluid">
         <div className="row align-items-center">
             <div className="col-sm-6">
                 <div className="page-title">
                     <h4>Dashboard</h4>
                         <ol className="breadcrumb m-0">
                             <li className="breadcrumb-item"><a href="javascript: void(0);">DNB</a></li>
                             <li className="breadcrumb-item"><a href="javascript: void(0);">Dashboard</a></li>
                         </ol>
                 </div>
             </div>
             <div className="col-sm-6">
                <div className="float-end d-none d-sm-block">
                   <Link to="/add-building.html" className="btn btn-success">Add Plot</Link>
                </div>
             </div>
         </div>
        </div>
     </div>
 {/*     <!-- end page title -->  */}   


    <div className="container-fluid">

        <div className="page-content-wrapper">


            <div className="row">
                


                <div className="col-xl-4">
                    <div className="row">
                        <div className="col-xl-6 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                    
                                        <p className="font-size-16">Plots</p>
                                        <div className="mini-stat-icon mx-auto mb-4 mt-3">
                                            <span className="avatar-title rounded-circle bg-soft-primary">
                                                    <i className="mdi mdi-cart-outline text-primary font-size-20"></i>
                                                </span>
                                        </div>
                                        
                                        <h5 className="font-size-22">{this.state.book.length}</h5>
                                         
                                        <p className="text-muted"></p>

                                        <div className="progress mt-3" style={{height: "4px;"}}>
                                            <div className="progress-bar progress-bar bg-primary" role="progressbar" style={{width: "70%"}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="70">
                                            </div>
                                            
                                        </div>
                                    </div>
                    
                                </div>
                            </div>

                            
                        </div>

                        <div className="col-xl-6 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <p className="font-size-16">Payment</p>
                                        <div className="mini-stat-icon mx-auto mb-4 mt-3">
                                            <span className="avatar-title rounded-circle bg-soft-success">
                                                    <i className="mdi mdi-account-outline text-success font-size-20"></i>
                                                </span>
                                        </div>
                                        <h5 className="font-size-22">5</h5>

                                        <p className="text-muted"></p>

                                        <div className="progress mt-3" style={{height: "4px;"}}>
                                            <div className="progress-bar progress-bar bg-success" role="progressbar" style={{width: "80%"}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="80">
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-4">Revenue Stastics</h4>

                            <div className="media">

                                <h4>$14,235 </h4>


                                <div className="media-body ps-3">

                                    <div className="dropdown">
                                        <button className="btn btn-light btn-sm dropdown-toggle" type="button"
                                            id="dropdownMenuButton" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            Today<i className="mdi mdi-chevron-down ms-1"></i>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a className="dropdown-item" href="#">Yesterday</a>
                                            <a className="dropdown-item" href="#">Last Week</a>
                                            <a className="dropdown-item" href="#">last Month</a>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="mt-3">
                                <div id="stastics-chart"></div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-body">
                                <h4 className="header-title">Payments</h4>        
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Reciept</th>
                                            <th>Date</th>
                                            <th>Total Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                {this.state.pay.map((itm,k)=>{
                                                  return(   
                                                <tr>
                                                    <td>{itm.recipt}</td>       
                                                    <td>{itm.payment_date}</td>
                                                    <td>{itm.total}</td>
                                                    <td><span className="badge badge-pill badge-soft-success font-size-13">Paid</span></td>
                                                </tr>)
                                                 })}       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

          

        </div>


    </div> {/* <!-- container-fluid --> */}
</div>
{/*       <!-- End Page-content --> */}

</div>
<div className="right-bar">
        <div data-simplebar className="h-100">
            <div className="rightbar-title d-flex align-items-center px-3 py-4">
            
                <h5 className="m-0 me-2">Settings</h5>

                <a href="javascript:void(0);" className="right-bar-toggle ms-auto">
                    <i className="mdi mdi-close noti-icon"></i>
                </a>
            </div>

           {/*  <!-- Settings --> */}
            <hr className="mt-0" />
            <h6 className="text-center mb-0">Choose Layouts</h6>

            <div className="p-4">
                <div className="mb-2">
                    <img src="assets/images/layouts/layout-1.jpg" className="img-fluid img-thumbnail" alt="layout-1"/>
                </div>

                <div className="form-check form-switch mb-3">
                    <input className="form-check-input theme-choice" type="checkbox" id="light-mode-switch" checked/>
                    <label className="form-check-label" for="light-mode-switch">Light Mode</label>
                </div>
    
                <div className="mb-2">
                    <img src="assets/images/layouts/layout-2.jpg" className="img-fluid img-thumbnail" alt="layout-2"/>
                </div>
                <div className="form-check form-switch mb-3">
                    <input className="form-check-input theme-choice" type="checkbox" id="dark-mode-switch" data-bsStyle="assets/css/bootstrap-dark.min.css" data-appStyle="assets/css/app-dark.min.css"/>
                    <label className="form-check-label" for="dark-mode-switch">Dark Mode</label>
                </div>
    
                <div className="mb-2">
                    <img src="assets/images/layouts/layout-3.jpg" className="img-fluid img-thumbnail" alt="layout-3"/>
                </div>
                <div className="form-check form-switch mb-5">
                    <input className="form-check-input theme-choice" type="checkbox" id="rtl-mode-switch" data-appStyle="assets/css/app-rtl.min.css"/>
                    <label className="form-check-label" for="rtl-mode-switch">RTL Mode</label>
                </div>

            
            </div>

        </div> {/* <!-- end slimscroll-menu--> */}
    </div>


      {/*   <!-- end main content--> */}

    </div>
    {/* <!-- END layout-wrapper -->

    <!-- Right Sidebar --> */}
    
  {/*   <!-- /Right-bar -->

    <!-- Right bar overlay--> */}
    <div className="rightbar-overlay"></div>
   
    
    </div>
    
}
}
export default withRouter(Home);//step9 add withrouter
// check all 9 steps
