import React, {Component} from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, withRouter, Redirect } from 'react-router-dom'; 
import AuthService from "../services/auth.service";                     
class Builderpayment extends React.Component { 
    constructor() {
        super();
        this.logOut = this.logOut.bind(this);
        this.state = {
            bpay:[] , currentUser: undefined
        }
    }
        componentDidMount(){
            const user = AuthService.getCurrentUser();
            if (!user) this.setState({ redirect: "/auth-login.html" });
            
            if (user) {
              this.setState({
                currentUser: user.full_name
              });
            }
            
            axios.get( encodeURI(process.env.REACT_APP_API_URL+'api/resource/Payments?fields=["recipt","builder","total","payment_date"]&filters=[["builder","=","'+user.uid+'"]]'), {
                headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}
                
            })
         
            .then(response=>{
                this.setState({bpay:response.data['data']})
                console.log({bpay:response.data['data']})
       })
       } 
       logOut = e => {
        e.preventDefault();
        AuthService.logout()
        const { history } = this.props;
              this.props.history.push("/auth-login.html");
              window.location.reload();
      }
    

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
       return <div>                           
        <div id="layout-wrapper">
            
            <div className="main-content">

                <div className="page-content">
                    <div className="page-title-box">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <div className="page-title">
                                        <h4>Builder Payment List</h4>
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Buildings</a></li>
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Buildings</a></li>
                                                <li className="breadcrumb-item active">Buildings List</li>
                                            </ol>
                                    </div>
                                </div>
                            <div className="col-sm-6">
                                    <div className="float-end d-none d-sm-block">
                                        <Link to="" className="btn btn-success">Add Building</Link>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>



                    
                    <div className="container-fluid">
                        <div className="page-content-wrapper">
                            <div className="row">
                                <div className="col-12">
                                <div className="card">
                        <div className="card-body">
                                <h4 className="header-title">Buildings</h4>        
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Reciept</th>
                                            <th>Date</th>
                                            <th>Total Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                {this.state.bpay.map((itm,k)=>{
                                                  return(   
                                                <tr>
                                                    <td>{itm.recipt}</td>       
                                                    <td>{itm.payment_date}</td>
                                                    <td>{itm.total}</td>
                                                    <td><span className="badge badge-pill badge-soft-success font-size-13">Paid</span></td>
                                                </tr>)
                                                 })}       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                                </div> 
                            </div> 
                        </div>                  
                    </div> 
                </div>
        

              
                
            </div>
           

        
      
        <div className="rightbar-overlay"></div>

      </div>
                            </div>
 }
}
export default Builderpayment;