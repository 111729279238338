import React, { Component } from "react";

class Checkbox extends Component {
  state = {
    checked: false
  };
  render() {
    return (
      <div>
        <input
          type="checkbox"
          value={this.state.checked}
          onClick={() => {
            this.setState(prevState => ({
              checked: !prevState.checked
            }));

            this.props.calculateTotal(this.props.value, !this.state.checked);
          }}
        />
        
      </div>
    );
  }
}
export default Checkbox;