import React, {Component} from 'react';
import axios from 'axios';
import AuthService from "../services/auth.service";//step1 add 
import { BrowserRouter, Route, Link, withRouter, Redirect } from 'react-router-dom';
class Bankhome extends React.Component {
    constructor(){
        super();
        this.logOut = this.logOut.bind(this);
        this.state = {
            
            cash : [], currentUser: undefined,
        }
        

    }
    componentDidMount(){
        const user = AuthService.getCurrentUser();
        if (!user) this.setState({ redirect: "/auth-login.html" });//reddirect:"???" ??? is the default link
        
        if (user) {
          this.setState({
            currentUser: user.full_name,
            userid: user.uid
          });
        }
        axios.get( encodeURI(process.env.REACT_APP_API_URL+'api/resource/Buildings'), {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({cash:response.data['data']})
            console.log({cash:response.data['data']})
          
        })
  
        AuthService.setKeys(user.uid);
        AuthService.showStorage();
    }
    logOut = e => {
        e.preventDefault();
        AuthService.logout()
        const { history } = this.props;
              this.props.history.push("/auth-login.html");//push("???")  ??? is the default link 
              window.location.reload();
      }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
      return <div>
        <div id="layout-wrapper">
            <div className="main-content">
                <div className="page-content">
                    <div className="page-title-box">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <div className="page-title">
                                        <h4>Dashboard</h4>
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">DNB</a></li>
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Dashboard</a></li>
                                            </ol>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="float-end d-none d-sm-block">
                                    <Link to="/add-building.html" className="btn btn-success">Add Plot</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="page-content-wrapper">
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                    
                                                        <p className="font-size-16"> Total Plots</p>
                                                        <div className="mini-stat-icon mx-auto mb-4 mt-3">
                                                            <span className="avatar-title rounded-circle bg-soft-primary">
                                                                    <i className="mdi mdi-cart-outline text-primary font-size-20"></i>
                                                                </span>
                                                        </div>
                                                        
                                                        <h5 className="font-size-22">{this.state.cash.length}</h5>
                                                        
                                                        <p className="text-muted">70% Finished</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <p className="font-size-16">Pending Payment</p>
                                                        <div className="mini-stat-icon mx-auto mb-4 mt-3">
                                                            <span className="avatar-title rounded-circle bg-soft-success">
                                                                    <i className="mdi mdi-account-outline text-success font-size-20"></i>
                                                                </span>
                                                        </div>
                                                        <h5 className="font-size-22">5,00,000</h5>

                                                        <p className="text-muted">80% Builders</p>

                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Payment Stastics</h4>
                                            <div className="media">
                                                <h4>INR 22,14,235 </h4>
                                                <div className="media-body ps-3">
                                                    <div className="dropdown">
                                                        <button className="btn btn-light btn-sm dropdown-toggle" type="button"
                                                            id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                            Today<i className="mdi mdi-chevron-down ms-1"></i>
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a className="dropdown-item" href="#">Yesterday</a>
                                                            <a className="dropdown-item" href="#">Last Week</a>
                                                            <a className="dropdown-item" href="#">last Month</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <div id="stastics-chart"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Pending Payouts </h4>
                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap mb-0">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Building</th>

                                                            <th>Builder</th>
                                                            <th>Amount</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>#2356</td>
                                                            <td>Building 004</td>
                                                            <td>Sree Venkiteswara Nagar </td>
                                                            <td>23,000.00</td>
                                                            <td><span
                                                                    className="badge badge-pill badge-soft-primary font-size-13">Pending</span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>#2564</td>
                                                            <td>Building 002</td>
                                                            <td>Sree Venkiteswara Nagar </td>
                                                            <td>23,000.00</td>
                                                            <td><span
                                                                    className="badge badge-pill badge-soft-success font-size-13">Paid</span>
                                                            </td>
                                                        </tr>

                                                    

                                                        <tr>
                                                            <td>#2125</td>
                                                            <td>Building 001</td>
                                                            <td>Sree Venkiteswara Nagar </td>
                                                            <td>23,000.00</td>
                                                            <td><span
                                                                    className="badge badge-pill badge-soft-success font-size-13">Paid</span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>#8587</td>
                                                            <td>Building 003</td>
                                                            <td>Sree Venkiteswara Nagar </td>
                                                            <td>23,000.00</td>
                                                            <td><span
                                                                    className="badge badge-pill badge-soft-primary font-size-13">Pending</span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>#2354</td>
                                                            <td>Building 001</td>
                                                            <td>Sree Venkiteswara Nagar </td>
                                                            <td>23,000.00</td>
                                                            <td><span
                                                                    className="badge badge-pill badge-soft-primary font-size-13">Pending</span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>#2354</td>
                                                            <td>Building 002</td>
                                                            <td>Sree Venkiteswara Nagar </td>
                                                            <td>23,000.00</td>
                                                            <td><span
                                                                    className="badge badge-pill badge-soft-primary font-size-13">Pending</span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>#2354</td>
                                                            <td>Building 002</td>
                                                            <td>Sree Venkiteswara Nagar </td>
                                                            <td>23,000.00</td>
                                                            <td><span
                                                                    className="badge badge-pill badge-soft-primary font-size-13">Pending</span>
                                                            </td>
                                                        </tr>


                                                        <tr>
                                                            <td>#2354</td>
                                                            <td>Building 002</td>
                                                            <td>Sree Venkiteswara Nagar </td>
                                                            <td>23,000.00</td>
                                                            <td><span
                                                                    className="badge badge-pill badge-soft-primary font-size-13">Pending</span>
                                                            </td>
                                                        </tr>


                                                        <tr>
                                                            <td>#2354</td>
                                                            <td>Building 001</td>
                                                            <td>Sree Venkiteswara Nagar </td>
                                                            <td>23,000.00</td>
                                                            <td><span
                                                                    className="badge badge-pill badge-soft-primary font-size-13">Pending</span>
                                                            </td>
                                                        </tr>


                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="rightbar-overlay"></div>
    </div>
}
}
export default withRouter(Bankhome);
