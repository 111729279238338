import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Redirect } from 'react-router';
import './index.css';


//import App from './App';

import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import Buildings from './pages/Buildings';
import Viewbuilding from './pages/Viewbuilding';
import Addplot from './pages/Addplot';
import Lands from './pages/Lands';
import Header from './components/Header';
import Footer from './components/Footer';
import Leftsidebar from './components/Leftsidebar';
import Bankhome from './pages/Bankhome';
import Bankleft from './components/Bankleft';
import Paymentpending from './pages/Paymentpending';
import Bview from './pages/Bview';
import FileUpload from './components/FileUpload'
import Paymentdetails from './pages/Paymentdetails';
import Builderpayment from './pages/Builderpayment';
import Addbuilding from './pages/Addbuilding';




ReactDOM.render(
  <React.StrictMode>
     <Router>
        <Switch>

        <Route exact path="/">
                <Login />           
        </Route> 

{/* builder */}
         
        <Route exact path="/index.html">
                        <Header/>
                        <Leftsidebar/>                 {/*  Dashboard */}
                            <Home />               
                        <Footer /> 
        </Route> 
        <Route exact path="/auth-login.html">               {/* login */}
                          <Login />
        </Route> 
        <Route exact path="/buildings.html">
                        <Header/>
                        <Leftsidebar/>
                           <Buildings />                      {/* buildings  */}                
                        <Footer />
        </Route>  
        <Route exact path="/building-detail/:slug2">
                        <Header/>
                        <Leftsidebar/>
                            <Viewbuilding />                     {/* for building view */}
 
        </Route> 
        {/*  <Route exact path="/add-plot.html">
                         <Header/>
                         <Leftsidebar/>
                              <Addplot />                      
                         <Footer />
        </Route> 
        <Route exact path="/add-building.html">
                         <Header/>
                         <Leftsidebar/>
                              <Addbuilding/>                      
                         <Footer />
        </Route>    */}
        <Route exact path="/land-details.html">
                         <Header/>
                         <Leftsidebar/>      
                             <Lands />                             {/*  view land and location etc. */}
                         <Footer />
        </Route>
        <Route exact path="/builder-paymentlist.html">
                         <Header/>
                         <Leftsidebar/>
                             <Builderpayment />                         {/*  builder payment */}
        </Route>









{/* bank */}  


        <Route exact path="/bank-home.html">
                         <Header/>
                         <Bankleft/>
                             <Bankhome />                                  {/*  bank Dashboard */}
                         <Footer />
        </Route> 
        <Route exact path="/payment.html">
                         <Header/>
                         <Bankleft/>
                             <Paymentpending />                            {/*  bank allocate payments to builder  */}                      
                    
        </Route> 
        <Route exact path="/bank-land-view">
                         <Header/>
                         <Bankleft/>
                             <Lands />                             {/*  view land and location etc. */}
                         <Footer />
        </Route>
        <Route exact path="/view-building-detail/:slug3">
                         <Header/>
                         <Bankleft/>                                            
                             <Bview />                             {/*   view all builders details(ploat,payments,task etc.)*/}
 
        </Route>
      
        <Route exact path="/payment-details.html">
                         <Header/>
                         <Bankleft/>
                             <Paymentdetails />                          {/*  bank payments details */}
        </Route>
       
        

  
    </Switch>
   
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
    