import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import AuthService from "../services/auth.service";
class Bankleft extends React.Component {

   constructor() {
        super();

        this.state = {
            userole:undefined,
            useFullname:undefined,
        }
    }
    componentDidMount(){
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
              currentUser: user.full_name,
              userole:localStorage.getItem('userRole'),
            });
            
          }
          AuthService.getRoles()
          console.log(localStorage.getItem('userRole'));
    }
    render() {
      return <div>
<div className="vertical-menu">

    <div data-simplebar className="h-100">


        <div className="user-sidebar text-center">
            <div className="dropdown">
                <div className="user-img">
                    <img src="assets/images/users/avatar-7.jpg" alt="" className="rounded-circle"/>
                    <span className="avatar-online bg-success"></span>
                </div>
                <div className="user-info">
                    <h5 className="mt-3 font-size-16 text-white">{this.state.currentUser}</h5>
                    <span className="font-size-13 text-white-50">{this.state.userole}</span>
                </div>
            </div>
        </div>



        {/*  <!--- Sidemenu --> */}
            <div id="sidebar-menu">
            {/*  <!-- Left Menu Start --> */}
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">Menu</li>

                    <li>
                        <Link to={process.env.PUBLIC_URL +"/bank-home.html"} className="waves-effect">
                            <i className="dripicons-home"></i><span className="badge rounded-pill bg-info float-end">3</span>
                            <span>Dashboard</span>
                        </Link>
                    </li>

                    {/* <li>
                        <Link to={process.env.PUBLIC_URL +"/view-building-detail"} className=" waves-effect">
                            <i className="dripicons-calendar"></i>
                            <span>View Buildings</span>
                        </Link>
                    </li> */}

                    
                    <li>
                        <Link to={process.env.PUBLIC_URL +"/bank-land-view"} className=" waves-effect">
                            <i className="dripicons-calendar"></i>
                            <span>View Land</span>
                        </Link>
                    </li>
                    
                    {/*
                    <li>
                        <Link to={process.env.PUBLIC_URL +"/building-detail/:slug2"} className=" waves-effect">
                            <i className="dripicons-message"></i>
                            <span>View Building</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={process.env.PUBLIC_URL +"/add-building.html"} className=" waves-effect">
                            <i className="dripicons-message"></i>
                            <span>Add Building</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={process.env.PUBLIC_URL +"/land-details.html"} className=" waves-effect">
                            <i className="dripicons-message"></i>
                            <span>Lands</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="#" className=" waves-effect">
                            <i className="dripicons-message"></i>
                            <span>Tasks</span>
                        </Link>
                    </li>
                     */}
                    <li>
                        <Link to="/payment.html" className=" waves-effect">
                            <i className="dripicons-message"></i>
                            <span>Payments</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/payment-details.html" className=" waves-effect">
                            <i className="dripicons-message"></i>
                            <span>View Payments</span>
                        </Link>
                    </li>
                </ul>
            </div>
        
    </div>
</div>
</div>
}
}
export default Bankleft;