import React, {Component} from 'react';
import {Link, withRouter, Redirect} from 'react-router-dom';
import axios from 'axios';
import AuthService from "../services/auth.service";



class Addtask  extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            task:[]
    }
}
    async componentDidMount(){
           
           const USR_TOKEN = localStorage.getItem("userToken")
           if(USR_TOKEN){
                   axios.get(process.env.REACT_APP_API_URL + 'api/resource/Building Tasks?fields=["task","name"]&filters=[["enabled","=","1"]]', {
                       headers : {'Authorization' : localStorage.getItem("userToken"), 
                       'content-type': 'application/json'}
                   })
                   .then(response=>{
                      // console.log(response.data['data'])
                       this.setState({task:response.data['data']})
                       
                   })
           }
        }
           handlestatusSubmit = e => {
            e.preventDefault()
           // alert(this.props.name)
            const statusData= {
                task:this.Taskid,
                status:this.Status,
               name:this.props.name,
                
            }
           console.log(statusData)
            axios.post(encodeURI(process.env.REACT_APP_API_URL+'api/method/dnb.api.add_building_task'), statusData,{
                headers : {'Authorization' : localStorage.getItem("userToken"), 
                'content-type': 'application/json'}
            })                
            .then( 
                res =>{ console.log(res) 
                    this.props.updateState() }
            )
            .catch(
                err =>{ console.log(err)}
            )
       } 
    handleUpdate = () => {
        this.props.updateState()
    }
    render() {
        return(
            <div id="myModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="myModalLabel">Add The Task And Status</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            
                        </button>
                    </div>
                    <form onSubmit={this.handlestatusSubmit}>
                    <div class="modal-body">
                        <h5 class="font-size-16">Task:</h5>
                        
                        <div class="col-sm-8">
                        
                        
                            <select class="form-select" aria-label="Default select example"
                            onChange={e => this.Taskid = e.target.value }>
                            
                            <option selected="">Select Task</option>
                            {this.state.task.map((itm,k)=>{
                                return(<option value={itm.name}>{itm.task}</option>)
                            })}
                            </select>
                        </div>
                        <br/>
                        <h5 class="font-size-16">Status:</h5>
                        <div class="col-sm-4">
                            <select class="form-select" aria-label="Default select example"
                            onChange={e => this.Status = e.target.value }>
                            <option selected="">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="On Progress">On Progress</option>  
                            <option value="Task Finished">Task Finished</option>  
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary waves-effect waves-light" onChange={e => this.props.name= e.target.value }>Save</button>
                        
                    </div>
                    </form>
                </div>
                
            </div>
            
        </div>
                                                            
        )
    }
}

export default Addtask;
