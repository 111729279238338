import React, {Component} from 'react';
import axios from 'axios';
import AuthService from "../services/auth.service"; 
import {Link, withRouter, Redirect} from 'react-router-dom';
class Buildings extends React.Component {
    constructor(){
        super();
         this.logOut = this.logOut.bind(this);
        this.state = {
            build : [],
             currentUser: undefined,
        }
    }
    componentDidMount(){
         const user = AuthService.getCurrentUser();
         if (!user) this.setState({ redirect: "/auth-login.html" });
         
         if (user) {
           this.setState({
             currentUser: user.full_name
           });
         }
    axios.get( encodeURI(process.env.REACT_APP_API_URL+'api/method/dnb.api.list_building'), {
        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
        'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({build:response.data['message']})
        //console.log({build:response.data['data']})
      
    })
   



    } 
    logOut = e => {
        e.preventDefault();
        AuthService.logout()
        const { history } = this.props;
              this.props.history.push("/auth-login.html");
              window.location.reload();
      }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
      return <div> 
        <div id="layout-wrapper">
            
            <div className="main-content">

                <div className="page-content">
                    <div className="page-title-box">
                        <div className="container-fluid">
                         <div className="row align-items-center">
                             <div className="col-sm-6">
                                 <div className="page-title">
                                     <h4>Buildings List</h4>
                                         <ol className="breadcrumb m-0">
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Buildings</a></li>
                                             <li className="breadcrumb-item"><a href="javascript: void(0);">Buildings</a></li>
                                             <li className="breadcrumb-item active">Buildings List</li>
                                         </ol>
                                 </div>
                             </div>
                             <div className="col-sm-6">
                                <div className="float-end d-none d-sm-block">
                                    <Link to="" className="btn btn-success">Add Building</Link>
                                </div>
                             </div>
                         </div>
                        </div>
                     </div>




                    <div className="container-fluid">

                        <div className="page-content-wrapper">

           
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
            
                                            <h4 className="header-title">Buildings</h4>
                                            <p className="card-title-desc"></p>
            
                                            <table id="datatable-buttons" className="table table-striped table-bordered dt-responsive nowrap" style={{width : "100%"}}>
                                                <thead>
                                                <tr>
                                                    <th>Land</th>
                                                    <th>Plot</th>
                                                    <th>Building Type</th>
                                                    <th>Sq. Feet</th>
                                                    <th>No.Of Stage</th>
                                                    <th>Last Updated Task</th>
                                                    
                                                </tr>
                                                </thead>
            
            
                                                <tbody>
                                                {this.state.build.map((itm,k)=>{
                                                   let stage=itm.tasks
                                                
                                                   //stage=itm.task.length-1
                                                   let lTask= ''
                                                   let Tno=''
                                                    if (stage.length >0)
                                                    {
                                                        lTask=stage[(stage.length-1)].task_display
                                                        Tno=stage[(stage.length-1)].serial_no

                                                    }
                                                  return(   
                                                <tr>
                                                    <td><Link to={process.env.PUBLIC_URL +'/building-detail/'+itm.name}>{itm.land_name}</Link></td>
                                                    <td>{itm.building_name}</td>
                                                    <td>{itm.building_type}</td>
                                                    <td>{itm.sq_feet}</td>
                                                    <td>{Tno}</td>{/* itm.tasks.length */}
                                                    <td> {lTask } </td>
                                                </tr>)
                                                 })}
                                                
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> 
                            </div> 

                        </div>
        
                        
                    </div> 
                </div>
        

              
                
            </div>
           

        
      
        <div className="rightbar-overlay"></div>

      </div> 
    </div>
    }
}
export default withRouter(Buildings);


