import React, {Component} from 'react';
import axios from 'axios';
import AuthService from "../services/auth.service";
import {Link, withRouter, Redirect} from 'react-router-dom';
import FileUpload from '../components/FileUpload'
class Form extends React.Component {
    
        
         
           
       
   
   handleSubmit = e => {
    e.preventDefault();
       const editData= {
           name:'f31c8e28ff',
          status:this.StatusType,
           
       }
      console.log(editData)
       axios.post(encodeURI(process.env.REACT_APP_API_URL+'api/method/dnb.api.update_task_status'),editData, {
       headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
       'content-type': 'application/json'}
   })                
       .then( 
           res =>{ console.log(res.data) }
       )
       .catch(
           err =>{ console.log(err)}
       )
       alert("success")
   }

   
    render() {
      return <div>
        <div className="row">
            <div className="col-xl-8">
                <div className="card">
                    <div className="card-body">
                            <form onSubmit={this.handleSubmit}>                         
                                    <select className="form-select" placeholder="status type" aria-label="Default select example"
                                    onChange={e => this.StatusType = e.target.value}>
                                        <option selected="">status type</option>
                                        <option value="Paid">Paid</option>
                                        <option value="Pending Approval">Pending Approval</option>
                                        <option value="Payment Pending"> Payment Pending</option>   
                                    </select>
                                <div className="mb-3 position-relative mt-4">
                                        <button type="submit" className="btn btn-outline-primary waves-effect waves-light">Save</button>
                                </div>   
                            </form>
                    </div>   
                </div>
            </div>
        </div>                                                           
 </div>
}
}
export default Form;