import React, {Component} from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, withRouter, Redirect } from 'react-router-dom';
import AuthService from "../services/auth.service";

class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false,
            message: ""
          };
    }
    async componentDidMount(){
        AuthService.getCurrentUser();
        const user = AuthService.getCurrentUser();
        if (user){
            if(localStorage.getItem('userRole')=='Bank'){
                this.setState({ redirect: "/bank-home.html" });}
            if(localStorage.getItem('userRole')=='Builder'){
        }       this.setState({ redirect: "/index.html" });}

    }
    handleLoginSubmit = e => {

        e.preventDefault();
           /* const loginData= {
                usr:this.userName,
                pwd:this.userPassword
            }

        axios.post(process.env.REACT_APP_API_URL + 'api/method/login', loginData,{
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })                
        .then(response=>{
            this.setState({userlogin:response.data})
            console.log(response.data)
        })
            .catch(
                err =>{ console.log(err)}
        )*/
        
        AuthService.login(this.userName, this.userPassword).then(
            () => {
              const { history } = this.props;
              
              if(localStorage.getItem('userRole')=='Bank'){
                this.props.history.push("/bank-home.html");
            }
            if(localStorage.getItem('userRole')=='Builder'){
                this.props.history.push("/index.html");
            }

              window.location.reload();
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

      return <div>
    <div className="home-center">
        <div className="home-desc-center">

            <div className="container">

                <div className="home-btn">  <Link to="/" className="text-white router-link-active"><i
                            className="fas fa-home h2"></i></Link></div>


                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card">
                            <div className="card-body">
                                <div className="px-2 py-3">


                                    <div className="text-center">
                                        <Link to={process.env.PUBLIC_URL + "/index.html"}>
                                            <img src="assets/images/logo-dark.png" height="22" alt="logo"/>
                                        </Link>

                                        <h5 className="text-primary mb-2 mt-4">Welcome Back !</h5>
                                        <p className="text-muted">{this.state.message}</p>
                                    </div>


                                    <form className="form-horizontal mt-4 pt-2" action="index.html" onSubmit={this.handleLoginSubmit}>

                                        <div className="mb-3">
                                            <label for="username">Username</label>
                                            <input type="text" className="form-control" id="username"
                                                placeholder="Enter username"
                                                onChange={e => this.userName = e.target.value}/>
                                        </div>

                                        <div className="mb-3">
                                            <label for="userpassword">Password</label>
                                            <input type="password" className="form-control" id="userpassword"
                                                placeholder="Enter password"
                                                onChange={e => this.userPassword = e.target.value} />
                                        </div>

                                        <div className="mb-3">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input"
                                                        id="customControlInline"/>
                                                    <label className="form-label"
                                                        for="customControlInline">Remember me</label>
                                                </div>
                                        </div>

                                        <div>
                                            <button className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit">Log In</button>
                                        </div>

                                        <div className="mt-4 text-center">
                                          <Link to={process.env.PUBLIC_URL + "/auth-recoverpw.html"} className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                        <div className="mt-5 text-center text-white">
                            <p>Don't have an account ? <Link to={process.env.PUBLIC_URL + "/auth-register.html"} className="fw-bold text-white"> Register</Link> </p>
                            <p>© <script>document.write(new Date().getFullYear())</script> Morvin. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
}
}
export default withRouter(Login);
