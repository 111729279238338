import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  async login(username, password) {
    const loginData= {
        usr:username,
        pwd:password
    }
    

    return axios
      .post(API_URL + "api/method/login", loginData)
      .then(response => {
        //console.log(response.data)
      if (response.data) {
        const userData= { 
            full_name:response.data['full_name'],
            uid:loginData.usr,
        }
        localStorage.setItem("user", JSON.stringify(userData));
        this.setKeys(loginData.usr)
        
        if(!localStorage.getItem("userType")){
            localStorage.setItem("userType", "Auth");
        }
      }

      return response.data;
    });
  }
  async setKeys(name) {
     
      const loginData= {
        name:name
      }
      axios.post(API_URL + 'api/method/dnb.api.get_key',loginData, {
          headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
          'content-type': 'application/json'}
      })
      .then(response=>{
        console.log("setKeys here")
          console.log(response.data['message'])
          localStorage.setItem("userToken", 'token ' + response.data['message'].api_key + ":" +response.data['message'].api_secret)
          this.getRoles()
        })



  }
  async getRoles() {
    console.log("role here")
    const USR_TOKEN = localStorage.getItem("userToken")
      if(USR_TOKEN){
          return axios.get(API_URL + '/api/method/dnb.api.get_roles',{
              headers : {'Authorization' : USR_TOKEN, 
              'content-type': 'application/json'}
          })
          .then(response=>{
                if(response.data['message'].includes("Builder"))
                    localStorage.setItem("userRole", "Builder");
                if(response.data['message'].includes("Bank"))
                    localStorage.setItem("userRole", "Bank");

              console.log(response.data['message'])
          })
      }
  }

  logout() {
      this.clearStorage()
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }
  tempRegister(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  randomString() {
      let length=6, chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
      return result;
  }
  clearStorage(){
      localStorage.removeItem("user");
      localStorage.removeItem("guestuser");
      localStorage.removeItem("userType");
      localStorage.removeItem("userToken");
      localStorage.removeItem('userIP');
      localStorage.removeItem('userRole');
      
  }
  showStorage(){
      console.log("user")
      console.log(localStorage.getItem("user"));
      console.log("guestuser")
      console.log(localStorage.getItem("guestuser"));
      console.log("userType")
      console.log(localStorage.getItem("userType"));
      console.log("userToken")
      console.log(localStorage.getItem("userToken"));
      console.log("userIP")
      console.log(localStorage.getItem('userIP'));
       console.log("userRole")
      console.log(localStorage.getItem('userRole'));
  }
}

export default new AuthService();
