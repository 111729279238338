import React, {Component} from 'react';
import AuthService from "../services/auth.service";
import { BrowserRouter, Route, Link, withRouter } from 'react-router-dom';


class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            userole:undefined,
            useFullname:undefined,
        }
    }
    async componentDidMount(){

        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
              currentUser: user.full_name,
              userole:localStorage.getItem('userRole'),
            });
            
          }
    }
    logOut= e => {
        e.preventDefault();
        AuthService.logout();
            const { history } = this.props;
            this.props.history.push("/auth-login.html");
            window.location.reload();
    }

    render() {
      return <div>
<header id="page-topbar">
<div className="navbar-header">
    <div className="d-flex">

         {/*   <!-- LOGO --> */}
     <div className="navbar-brand-box">
        <Link to={process.env.PUBLIC_URL +"/auth-login.html"} className="logo logo-dark">
            <span className="logo-sm">
                <img src="assets/images/logo-sm.png" alt="" height="22"/>
            </span>
            <span className="logo-lg">
                <img src="assets/images/logo-dark.png" alt="" height="40"/>
            </span>
        </Link>

        <Link to={process.env.PUBLIC_URL +"/index.html"} className="logo logo-light">
            <span className="logo-sm">
                <img src="assets/images/logo-sm.png" alt="" height="22"/>
            </span>
            <span className="logo-lg">
                <img src="assets/images/logo-light.png" alt="" height="40"/>
            </span>
        </Link>
    </div>

        <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
            <i className="mdi mdi-menu"></i>
        </button>



    </div>

    <div className="d-flex">

        <div className="dropdown d-none d-lg-inline-block ms-1">
            <button type="button" className="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                <i className="mdi mdi-fullscreen"></i>
            </button>
        </div>

       

        <div className="dropdown d-inline-block">
            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img className="rounded-circle header-profile-user" src="assets/images/users/avatar-7.jpg"
                    alt="Header Avatar"/>
                <span className="d-none d-xl-inline-block ms-1">{this.state.currentUser}</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-end">
             {/*    <!-- item--> */}
                <a className="dropdown-item" href="#"><i className="mdi mdi-account-circle-outline font-size-16 align-middle me-1"></i> Profile</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item text-danger" href="#" onClick={this.logOut}><i className="mdi mdi-power font-size-16 align-middle me-1 text-danger"></i> Logout</a>
            </div>
        </div>



    </div>
</div>
</header>
</div>
}
}
export default withRouter(Header);