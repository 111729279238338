import React, {Component} from 'react';
import {Link} from 'react-router-dom';
class Register extends React.Component {
    render() {
      return <div>
<div className="home-center">
        <div className="home-desc-center">

            <div className="container">

                <div className="home-btn"><Link to="/" className="text-white router-link-active"><i
                            className="fas fa-home h2"></i></Link></div>


                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card">
                            <div className="card-body">
                                <div className="px-2 py-3">


                                    <div className="text-center">
                                        <Link to={process.env.PUBLIC_URL +"/index.html"}>
                                            <img src="assets/images/logo-dark.png" height="22" alt="logo"/>
                                        </Link>

                                        <h5 className="text-primary mb-2 mt-4">Free Register</h5>
                                        <p className="text-muted">Get your free Morvin account now.</p>
                                    </div>


                                    <form className="form-horizontal" action="index.html">
            
                                        <div className="mb-3">
                                            <label for="useremail">Email</label>
                                            <input type="email" className="form-control" id="useremail" placeholder="Enter email"/>        
                                        </div>
                
                                        <div className="mb-3">
                                            <label for="username">Username</label>
                                            <input type="text" className="form-control" id="username" placeholder="Enter username"/>
                                        </div>
                
                                        <div className="mb-3">
                                            <label for="userpassword">Password</label>
                                            <input type="password" className="form-control" id="userpassword" placeholder="Enter password"/>        
                                        </div>
                    
                                        <div className="mt-4">
                                            <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">Register</button>
                                        </div>
                
                                        <div className="mt-4 text-center">
                                            <p className="mb-0">By registering you agree to the Morvin  <Link to="#" className="text-primary">Terms of Use</Link></p>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                        <div className="mt-5 text-center text-white">
                            <p>Already have an account ?  <Link to={process.env.PUBLIC_URL +"/auth-login.html"} className="fw-bold text-white"> Login </Link> </p>
                            <p>© <script>document.write(new Date().getFullYear())</script> Morvin. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                        </div>
                    </div>
                </div>

            </div>


        </div>
        
    </div>
    </div>
}
}
export default Register;


   

