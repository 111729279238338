import React from 'react'
import axios from 'axios';

class FileUpload extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selectedFile:'',
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            selectedFile: event.target.files[0],
          })
    }

    submit(){
        const data = new FormData() 
        data.append('file', this.state.selectedFile)
        //data.append('doctype', 'Task Image');
		//data.append('docname', this.props.docname);
//console.warn(this.state.selectedFile);
        

        axios.post(encodeURI(process.env.REACT_APP_API_URL+'api/method/upload_file'), data, { // receive two parameter endpoint url ,form data 
        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
        'content-type': 'application/json'}
        })
        .then(res => { // then print response status
           // console.warn(res);
           // console.log(res.data['message'])
            let Fileinfo=res.data['message']
            this.setState({img:Fileinfo.file_url})
            console.log(this.props.no)
            let fileinf={ image:Fileinfo.file_url, field:this.props.no, name:this.props.docname }
           
            console.log(fileinf)
             axios.post(encodeURI(process.env.REACT_APP_API_URL+'api/method/dnb.api.update_task_image'), fileinf, { // receive two parameter endpoint url ,form data 
                    headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                    'content-type': 'application/json'}
                })
                .then(res2 => { // then print response status
                     console.warn(res2);
                     
                    
                      })
                    
        })
    }

    render(){
        return(
<div>
    <div className="row">
        <p>Image {this.props.no} </p>
            <div className="col-md-8">
                <div className="mb-3 position-relative">
                    <div className="form-group">
                        <div className="mb-3">
                            <input type="file" className="form-control" name="upload_file" onChange={this.handleInputChange} />
                        </div>
                            <button type="submit" className="btn btn-outline-primary waves-effect waves-light" onClick={()=>this.submit()}>Save</button>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3 position-relative">
         {(() => {
        if(this.state.img) { 
            return(<img src={process.env.REACT_APP_API_URL+this.state.img} alt="" className="rounded avatar-md"/>);
                }
        else {
            return ""
                     }
        })()}
                </div>
            </div>                                
    </div>            
</div>
        )  
    }
}

export default FileUpload;