import React, {Component} from 'react';
class Footer extends React.Component {
    render() {
      return <div>
<footer className="footer">
<div className="container-fluid">
    <div className="row">
        <div className="col-sm-6">
            <script>document.write(new Date().getFullYear())</script> © DNB Consultants.
        </div>
        <div className="col-sm-6">
            <div className="text-sm-end d-none d-sm-block">
                Crafted with <i className="mdi mdi-heart text-danger"></i> by PrismaticSoft
            </div>
        </div>
    </div>
</div>
</footer>
</div>
}
}
export default Footer;